<template>
  <div>
    <div class="bascinfo">
      订单号：{{ stldata.orderno }}
      <span class="ml20">患者姓名：{{ stldata.name }}</span>
    </div>
    <div ref="canavs">
      <div v-if="loading" class="_load">
        <i class="el-icon-loading"></i>渲染中..
      </div>
    </div>
  </div>
</template>
<script>
import * as THREE from "three";

import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
export default {
  name: "stlfile",
  props: ["datainfo", "width", "height"],
  mounted() {
    if (!this.datainfo) {
      let query = this.$route.query;
      this.stldata = {
        orderno: query.orderno,
        name: query.name,
        url: query.url,
      };
    } else {
      this.stldata = {
        orderno: this.datainfo.orderno,
        name: this.datainfo.name,
        url: this.datainfo.url,
      };
    }
    this.boxwidth = this.width ? this.width : window.innerWidth - 20;
    this.boxheight = this.height ? this.height : window.innerHeight * 0.9;
    // console.log(window.innerWidth,document.body.clientWidth)
    this.init();
    this.animate();
  },
  data() {
    return {
      loading: true,
      container: null,
      camera: null,
      scene: null,
      cameraTarget: null,
      renderer: null,
      stats: null,
      controls: null,
      stldata: {},
      boxwidth: 1000,
      boxheight: 500,
      options: {
        color: "0xff5533",
        specular: "0x111111",
        shininess: 100,
        position: [0, -0.25, 0.6],
        rotation: [0, -Math.PI / 2, 0],
        scale: [0.03, 0.03, 0.03],
      },
    };
  },
  methods: {
    loadStllist() {
      let promises = [];
      promises.push(this.loadStl(this.stldata.url));
      return Promise.all(promises)
        .then((data) => {
          data.forEach((element) => {
            //加载成功后，加到场景中
            this.scene.add(element);
          });
          this.createControls();
          this.loading = false;
          return data;
        })
        .catch();
    },
    loadStl(url) {
      return new Promise(function (resolve) {
        const loader = new STLLoader();
        loader.load(url, (geometry) => {
          const material = new THREE.MeshPhongMaterial({
            color: 0xff5533,
            specular: 0x111111,
            shininess: 100,
          });
          const mesh = new THREE.Mesh(geometry, material);
          mesh.position.set(0, -0.15, 0);
          mesh.rotation.set(0, 0, 0);
          mesh.scale.set(0.04, 0.04, 0.04);
          resolve(mesh);
        });
      });
    },
    init() {
      this.container = this.$refs.canavs;

      this.camera = new THREE.PerspectiveCamera(
        45,
        this.boxwidth / this.boxheight,
        1,
        1000
      );
      this.camera.position.set(1, 0, 3);

      this.cameraTarget = new THREE.Vector3(0, 0, 0);

      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xffffff);
      this.scene.fog = new THREE.Fog(0x72645b, 2, 15);

      this.loadStllist();

      this.scene.add(new THREE.HemisphereLight(0xffffff, 0x111122));

      this.addShadowedLight(1, 1, 1, 0xffffff, 0.4);
      //   this.addShadowedLight(0.5, 1, -1, 0xffaa00, 1);
      // renderer

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.boxwidth, this.boxheight);
      this.renderer.outputEncoding = THREE.sRGBEncoding;

      this.renderer.shadowMap.enabled = true;

      this.container.appendChild(this.renderer.domElement);

      // stats

      // this.stats = new Stats();
      // this.container.appendChild( this.stats.dom );
      //

      window.addEventListener("resize", this.onWindowResize, false);
    },
    addShadowedLight(x, y, z, color, intensity) {
      const directionalLight = new THREE.DirectionalLight(color, intensity);
      directionalLight.position.set(x, y, z);
      this.scene.add(directionalLight);

      directionalLight.castShadow = true;

      const d = 1;
      directionalLight.shadow.camera.left = -d;
      directionalLight.shadow.camera.right = d;
      directionalLight.shadow.camera.top = d;
      directionalLight.shadow.camera.bottom = -d;

      directionalLight.shadow.camera.near = 1;
      directionalLight.shadow.camera.far = 4;

      directionalLight.shadow.bias = -0.002;
    },
    onWindowResize() {
      if (this.controls) {
        this.controls.handleResize();
      }
    },
    animate() {
      requestAnimationFrame(this.animate);
      if (this.controls) {
        this.controls.update();
      }
      this.render();
    },
    render() {
      this.camera.lookAt(this.cameraTarget);
      this.renderer.render(this.scene, this.camera);
    },
    createControls() {
      /* 轨迹球控件 */

      const trackballControls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );
      //   this.renderer.domElement.draggable = true;
      //   console.dir(trackballControls);
      //   console.dir(this.cameraTarget);
      /* 属性参数 */
      trackballControls.rotateSpeed = 3; // 旋转速度
      trackballControls.zoomSpeed = 1; // 缩放速度
      trackballControls.panSpeed = 1; // 平controls
      trackballControls.noPan = false;

      trackballControls.staticMoving = true; // 静止移动，为 true 则没有惯性
      trackballControls.dynamicDampingFactor = 0.2; // 阻尼系数 越小 则滑动越大

      trackballControls.rotateCallback = () => {};

      trackballControls.domElement.addEventListener("wheel", function () {
        trackballControls.update();
      });
      this.controls = trackballControls;
    },
  },
};
</script>
<style lang="scss">
body {
  min-width: auto;
}
</style>
<style lang="scss" scoped>
.bascinfo {
  line-height: 22px;
  padding: 3px 10px;
  font-weight: 600;
}
._load {
  position: absolute;
  top: 50%;
  left: 46%;
  color: #666;
}
</style>